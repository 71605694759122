import classNames from 'classnames/bind';
import ImagePlaceholder from 'components/shared/image-placeholder';
import { m, LazyMotion, domAnimation, useAnimation } from 'framer-motion';
import { useLottie } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './any-scale.module.scss';
import dashboardAnimationData from './assets/dashboard-animation-data.json';
import flagsAnimationData from './assets/flags-animation-data.json';
import Circle1Icon from './images/circle-1-icon.inline.svg';
import Circle1 from './images/circle-1.inline.svg';
import Circle2Icon from './images/circle-2-icon.inline.svg';
import Circle2 from './images/circle-2.inline.svg';
import Line1 from './images/line1.inline.svg';
import Line2 from './images/line2.inline.svg';
import Lines2Md from './images/lines2-md.inline.svg';
import TerminalCursor from './images/terminal-cursor.inline.svg';

const cx = classNames.bind(styles);

const circleVariants = {
  initial: {
    scale: 0,
    top: 0,
    translateY: 0,
    translateX: '-50%',
    width: 16,
    height: 16,
    borderWidth: 3,
  },
  animate: ({ top, translateY }) => ({
    scale: 1,
    top,
    translateY,
    translateX: '-50%',
    width: 72,
    height: 72,
    borderWidth: 8,
    transition: {
      duration: 1,
      ease: [0.35, 0.35, 0, 1],
      scale: {
        duration: 0.5,
        ease: 'linear',
      },
      width: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.35, 0.35, 0, 1],
      },
      height: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.35, 0.35, 0, 1],
      },
      borderWidth: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.35, 0.35, 0, 1],
      },
    },
  }),
  exit: {
    scale: 0,
    top: '100%',
    width: 16,
    height: 16,
    translateY: 0,
    translateX: '-50%',
    borderWidth: 3,
    transition: {
      duration: 1,
      ease: [0.35, 0.35, 0, 1],
      scale: {
        delay: 0.7,
        duration: 0.3,
        ease: 'linear',
      },
      width: {
        duration: 0.3,
        ease: [0.35, 0.35, 0, 1],
      },
      height: {
        duration: 0.3,
        ease: [0.35, 0.35, 0, 1],
      },
      borderWidth: {
        duration: 0.3,
        ease: [0.35, 0.35, 0, 1],
      },
    },
  },
};

const circleIconVariants = {
  initial: {
    scale: 0,
  },
  animate: {
    scale: 1,
    transition: {
      delay: 0.5,
      duration: 0.5,
      ease: [0.35, 0.35, 0, 1],
    },
  },
  exit: {
    scale: 0,
    transition: {
      duration: 0.3,
      ease: [0.35, 0.35, 0, 1],
    },
  },
};

const firstSidesVariants = {
  initial: {},
  animate: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.2,
    },
  },
};

const codeBlockVariants = {
  initial: {
    translateY: -20,
    opacity: 0,
  },
  animate: {
    translateY: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.35, 0.35, 0, 1],
    },
  },
};

const AnyScale = () => {
  const [firstSidesSectionRef, isFirstSidesSectionInView] = useInView({
    triggerOnce: true,
    threshold: 0.8,
    delay: 1500,
  });
  const firstSidesControls = useAnimation();

  useEffect(() => {
    if (isFirstSidesSectionInView) {
      firstSidesControls.start('animate');
      setTimeout(() => firstSidesControls.start('exit'), 2000);
    }
  }, [isFirstSidesSectionInView, firstSidesControls]);

  const [secondSidesSectionRef, isSecondSidesSectionInView] = useInView({ triggerOnce: true, threshold: 0.8 });
  const secondSidesControls = useAnimation();

  useEffect(() => {
    if (isSecondSidesSectionInView) {
      secondSidesControls.start('animate');
      setTimeout(() => secondSidesControls.start('exit'), 2000);
    }
  }, [isSecondSidesSectionInView, secondSidesControls]);

  const [dashboardWrapperRef, isDashboardWrapperInView] = useInView({ triggerOnce: true, threshold: 0.8 });
  const [isDashboardAnimationReady, setIsDashboardAnimationReady] = useState(false);

  const [dashboardAnimation, dashboardAnimationRef] = useLottie(
    { animationData: dashboardAnimationData },
    { loaded_images: () => setIsDashboardAnimationReady(true) }
  );

  useEffect(() => {
    if (isDashboardWrapperInView && isDashboardAnimationReady) dashboardAnimation.play();
  }, [isDashboardWrapperInView, isDashboardAnimationReady, dashboardAnimation]);

  const [flagsWrapperRef, isFlagsWrapperInView] = useInView({ triggerOnce: true, threshold: 0.8 });
  const [isFlagsAnimationReady, setIsFlagsAnimationReady] = useState(false);

  const [flagsAnimation, flagsAnimationRef] = useLottie(
    { animationData: flagsAnimationData },
    { loaded_images: () => setIsFlagsAnimationReady(true) }
  );

  useEffect(() => {
    if (isFlagsWrapperInView && isFlagsAnimationReady) flagsAnimation.play();
  }, [isFlagsWrapperInView, isFlagsAnimationReady, flagsAnimation]);

  const shouldCodeBlocksBeAnimated = typeof window !== 'undefined' && window.matchMedia('(min-width: 1024px)').matches;

  return (
    <section className={cx('wrapper', 'safe-paddings')}>
      <LazyMotion features={domAnimation}>
        <div className="container-sm-v2">
          <h2 className={cx('title')}>On your machines, our cloud, or both</h2>
          <p className={cx('description')}>Scale from solo developers to large enterprises</p>
          <div className={cx('sides', 'container')} ref={firstSidesSectionRef}>
            <div className={cx('side')}>
              <h3 className={cx('side-title')}>Use the same script for local, distributed, and cloud tests</h3>
              <p className={cx('side-description')}>
                Migrate from local tests to the cloud using the same script for both environments.
              </p>
            </div>
            <m.div className={cx('line-wrapper')} initial="initial" animate={firstSidesControls}>
              <Line1 className={cx('line-1')} />

              <m.div
                className={cx('circle', 'circle-1')}
                custom={{ top: '50%', translateY: '-50%' }}
                variants={circleVariants}
              >
                <m.div variants={circleIconVariants}>
                  <Circle1Icon className={cx('circle-icon')} />
                </m.div>
              </m.div>
              <Circle1 className={cx('circle-md', 'circle-md-1')} />
            </m.div>
            <m.div
              className={cx('side', 'side-code-blocks-wrapper')}
              initial="initial"
              animate={shouldCodeBlocksBeAnimated ? firstSidesControls : undefined}
              variants={shouldCodeBlocksBeAnimated ? firstSidesVariants : undefined}
            >
              <m.div
                className={cx('side-code-block')}
                variants={shouldCodeBlocksBeAnimated ? codeBlockVariants : undefined}
              >
                <span className={cx('side-code-block-terminal-cursor-wrapper')}>
                  <TerminalCursor className={cx('side-code-block-terminal-cursor')} />
                </span>
                <code className={cx('side-code-block-code')}>k6 run script.js</code>
                <span className={cx('side-code-block-badge')}>Local</span>
              </m.div>
              <m.span
                className={cx('side-code-block-divider')}
                variants={shouldCodeBlocksBeAnimated ? codeBlockVariants : undefined}
              >
                or
              </m.span>
              <m.div
                className={cx('side-code-block')}
                variants={shouldCodeBlocksBeAnimated ? codeBlockVariants : undefined}
              >
                <span className={cx('side-code-block-terminal-cursor-wrapper')}>
                  <TerminalCursor className={cx('side-code-block-terminal-cursor')} />
                </span>
                <code className={cx('side-code-block-code')}>kubectl apply -f k6.yaml</code>
                <span className={cx('side-code-block-badge')}>k8s</span>
              </m.div>
              <m.span
                className={cx('side-code-block-divider')}
                variants={shouldCodeBlocksBeAnimated ? codeBlockVariants : undefined}
              >
                or
              </m.span>
              <m.div
                className={cx('side-code-block')}
                variants={shouldCodeBlocksBeAnimated ? codeBlockVariants : undefined}
              >
                <span className={cx('side-code-block-terminal-cursor-wrapper')}>
                  <TerminalCursor className={cx('side-code-block-terminal-cursor')} />
                </span>
                <code className={cx('side-code-block-code')}>k6 cloud script.js</code>
                <span className={cx('side-code-block-badge', 'gradient')}>Cloud</span>
              </m.div>
            </m.div>
          </div>
          <Lines2Md className={cx('line-3')} />
          <div className={cx('side-dashboard-wrapper', 'container')} ref={dashboardWrapperRef}>
            <div className={cx('side-dashboard')} ref={dashboardAnimationRef} />
            <ImagePlaceholder width={1006} height={589} />
          </div>
          <div className={cx('sides', 'container')} ref={secondSidesSectionRef}>
            <div className={cx('side', 'with-margin-bottom', 'side-flags-wrapper')} ref={flagsWrapperRef}>
              <div className={cx('side-flags')} ref={flagsAnimationRef} />
              <ImagePlaceholder width={384} height={175} />
            </div>
            <m.div className={cx('line-wrapper')} initial="initial" animate={secondSidesControls}>
              <Line2 className={cx('line-2')} />

              <m.div
                className={cx('circle', 'circle-2')}
                custom={{ top: 190, translateY: 0 }}
                variants={circleVariants}
              >
                <m.div variants={circleIconVariants}>
                  <Circle2Icon className={cx('circle-icon')} />
                </m.div>
              </m.div>
              <Circle2 className={cx('circle-md', 'circle-md-2')} />
            </m.div>
            <div className={cx('side', 'with-margin-bottom')}>
              <h3 className={cx('side-title')}>Run tests from 21 load zones</h3>
              <p className={cx('side-description')}>Simulate real global traffic patterns.</p>
            </div>
          </div>
          <Lines2Md className={cx('line-4')} />
        </div>
      </LazyMotion>
    </section>
  );
};

export default AnyScale;
